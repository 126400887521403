<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        :placeholder="$t('auditLog.userAccount')"
        maxlength="50"
        v-model="listQuery.userAccount"
        clearable
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />
      <el-date-picker
        class="filter-item"
        v-model="pickerOpts.pickerData"
        type="datetimerange"
        :picker-options="pickerOpts"
        @change="setDateRange"
        :range-separator="$t('commons.rangeSeparator')"
        :start-placeholder="$t('commons.startDate')"
        :end-placeholder="$t('commons.endDate')"
      >
      </el-date-picker>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        :loading="listLoading"
        @click="handleFilter"
        >{{ $t("commons.search") }}
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      @sort-change="handleSort"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :resizable="false"
        :label="$t('commons.index')"
        width="80"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="creationDate"
        sortable="custom"
        :label="$t('auditLog.creationDate')"
        width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>
            {{
              scope.row.operationTimestamp | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="userAccount"
        sortable="custom"
        :label="$t('auditLog.userAccount')"
        width="110"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ip"
        sortable="custom"
        :label="$t('auditLog.ip')"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="host"
        sortable="custom"
        :label="$t('auditLog.host')"
        width="220"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="operation"
        sortable="custom"
        :label="$t('auditLog.operation')"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="module"
        sortable="custom"
        :label="$t('auditLog.module')"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="sourceApp"
        sortable="custom"
        :label="$t('commons.appName')"
        width="130"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="message" :label="$t('auditLog.description')">
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.current"
      :limit.sync="listQuery.rowCount"
      @pagination="getLoginList"
    />
  </div>
</template>

<script>
import { getLoginList, findAppList } from "@/api/ruge/logger/tenant/auditLog";
import Pagination from "@/components/Pagination";
import { buildSortStr } from "@/utils/global";

export default {
  name: "AuditList",
  components: { Pagination },
  data() {
    let opt = {
      appList: [],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        current: 1,
        rowCount: 10,
        // 排序条件,格式：column1:asc;column2:desc
        sortStr: null,
        userAccount: undefined,
        startDate: undefined,
        endDate: undefined,
        tenantId: undefined,
      },
      // 日期范围查询组件
      pickerOpts: {
        // 默认日期
        pickerData: undefined,
        // 快捷选项
        shortcuts: undefined,
      },
    };
    // 设置Picker快捷选项
    this.setPickerShortcuts(opt);
    return opt;
  },
  created() {
    this.getLoginList();
    this.getAppList();
  },
  watch: {
    // 切换语言重新设置快捷选项
    language: function () {
      this.setPickerShortcuts();
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    getAppList() {
      findAppList({ classifyCode: "APP_LIST" }).then((r) => {
        this.appList = r;
      });
    },
    getLoginList() {
      let tenantId = this.$store.getters.currentTenant;
      this.listQuery.tenantId = tenantId;
      this.listLoading = true;
      getLoginList(this.listQuery).then((response) => {
        this.list = response.rows;
        this.total = response.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.current = 1;
      this.getLoginList();
    },
    // 处理动态排序
    handleSort(column) {
      this.listQuery.sortStr = buildSortStr(column);
      this.getLoginList();
    },
    // 设置日期范围查询条件
    setDateRange(dateRange) {
      if (dateRange) {
        this.listQuery.startDate = dateRange[0].getTime();
        this.listQuery.endDate = dateRange[1].getTime();
      } else {
        // 清空
        this.listQuery.startDate = null;
        this.listQuery.endDate = null;
      }
    },
    // 设置Picker快捷选项
    setPickerShortcuts(opt = this) {
      opt.pickerOpts.shortcuts = [
        {
          text: this.$t("commons.lastDay"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("commons.lastWeek"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("commons.lastMonth"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
      ];
    },
  },
};
</script>
